<template>
  <div class="inscription page" v-loading="isLoading">
    <router-link class="back" :to="{ name: 'landing' }">
      <img src="../assets/svg/back.svg" alt="retour" width="14" height="23" />
    </router-link>
    <section class="section-header">
      <div class="container">
        <h2 v-html="$t('register1.title')"></h2>
        <p class="tagline" v-html="$t('register1.resume')"></p>

        <ValidationObserver ref="validationObserver" v-slot="{ valid }" @submit.prevent="submit()" class="form">
          <form>
            <div class="form-row">

              <!-- Name -->
              <div class="form-group">
                <label for class="label" v-html="$t('register1.name.label')"></label>
                <ValidationProvider :rules="{ required: true, regex: /^(?:(?![×÷])[ \-'aA-zZÀ-ÿ])+$/ }" v-slot="{ errors }" :name="$t('register1.name.field')">
                  <input type="text" class="input-text" v-model="name" />
                  <span class="error">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>

              <!-- Card number -->
              <div class="form-group">
                <label for class="label">
                  <span v-html="$t('register1.card.label')"></span>
                  <button class="btn-info" type="button" tabindex="5" @click="$eventHub.$emit('modal-card')">?</button>
                </label>
                <ValidationProvider rules="required|numeric|digits:19" v-slot="{ errors }" :name="$t('register1.card.field')">
                  <div class="input-container">
                    <word-limit :word="card" :max="19" />
                    <input type="text" class="input-text" maxlength="19" v-model="card" />
                  </div>
                  <span class="error">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>

            <div v-if="apiError" class="form-error">
              <span class="error">{{ apiError }}</span>
            </div>

            <VueRecaptcha
              v-if="recaptchaSiteKey !== 'disabled'"
              ref="recaptcha"
              :sitekey="recaptchaSiteKey"
              size="invisible"
              class="captcha"
              :loadRecaptchaScript="true"
              @verify="response => captchaResponse = response"
              @expired="resetRecaptcha"
              @render="resetRecaptcha"
              @error="resetRecaptcha"
            ></VueRecaptcha>
            <div class="form-validate">
              <button class="btn btn-submit" :disabled="!valid || !isRecaptchaOk" :class="{ disabled: !valid || !isRecaptchaOk }">Valider</button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </section>

    <FooterSmall class="end"/>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import VueRecaptcha from 'vue-recaptcha'
import { config } from '@/config.js'
import WordLimit from '@/components/WordLimit.vue'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    VueRecaptcha,
    WordLimit
  },
  data () {
    return {
      isLoading: false,
      name: this.$store.state.subscribeData ? this.$store.state.subscribeData.lastName : '',
      card: this.$store.state.subscribeData ? this.$store.state.subscribeData.cardNum : '',
      apiError: null,
      recaptchaSiteKey: config.recaptcha.sitekey,
      captchaResponse: config.recaptcha.sitekey === 'disabled' ? 'disabled' : false
    }
  },
  computed: {
    isRecaptchaOk () {
      return this.captchaResponse || (config.recaptcha.sitekey === 'disabled')
    }
  },
  watch: {
    name () { this.apiError = null },
    card () { this.apiError = null }
  },
  methods: {
    resetRecaptcha (info) {
      if (this.recaptchaSiteKey !== 'disabled') {
        this.$refs.recaptcha.reset()
        this.$refs.recaptcha.execute()
      }
    },
    submit () {
      this.isLoading = true
      this.$store.dispatch('register1', {
        cardNum: this.card,
        lastName: this.name,
        recaptcha: this.captchaResponse
      })
        .then(() => {
          this.$router.push({ name: 'register2' })
        })
        .catch(error => {
          this.resetRecaptcha('API load error')
          this.apiError = this.$t(error.message)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.inscription {
  .section-header {
    justify-self: center;
    align-self: center;
  }

  display: flex;
  flex-direction: column;
}
.input-container {
  position: relative;
}
input::-ms-clear {
  // Fix IE11
  display: none;
}
.footer-small.end {
  margin-top: auto;
}
</style>
