<template>
  <div class="input-password__container">
    <input :type="inputType" class="input-password" ref="password" :value="password" @input="event => $emit('input', event.target.value)"/>
    <span class="input-password--eye" :class="{ 'is-crossed-out': inputType === 'text' }" tabindex="10" @click.prevent="toggleInputType()">
      <img src="../assets/svg/icon-password.svg" alt="Oeuil" width="22" height="18" >
    </span>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      inputType: 'password'
    }
  },
  computed: {
    password () {
      return this.value
    }
  },
  methods: {
    toggleInputType () {
      this.inputType = this.inputType === 'password' ? 'text' : 'password'
    }
  }
}
</script>

<style lang="scss" scoped>
.input-password__container {
  position: relative;
  display: block;
}
.input-password {
  padding-right: 50px;
}
.input-password--eye {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  display: inline-block;
  background: none;
  border: 0;
  padding: 0;
  margin: 0;
  font-size: 0;

  &:after {
    position: absolute;
    content: "";
    display: block;
    width: 124%;
    height: 1.2px;
    top: 50%;
    left: 50%;
    background: #FFF;
    transform: translateY(-50%) translateX(-50%) rotate(-45deg) scaleX(0.1);
    opacity: 0;
    transition: transform 0.3s, opacity 0.1s 0.2s;
    will-change: transform, opacity;
  }

  &.is-crossed-out {
    &:after {
      transform: translateY(-50%) translateX(-50%) rotate(-45deg);
      opacity: 1;
      transition-delay: 0s, 0s;
    }
  }
}
</style>
