<template>
  <div class="mot-de-passe page">
    <router-link class="back" :to="{ name: 'register2' }">
      <img src="../assets/svg/back.svg" alt="retour" width="14" height="23" />
    </router-link>
    <section class="section-header">
      <div class="container">
        <h2>Configurez le mot de passe de votre nouvel Espace Club</h2>

        <ValidationObserver v-slot="{ valid }" @submit.prevent="submit()">
          <form class="form">

            <!-- Password -->
            <div class="form-group">
              <label for class="label">Mot de passe*</label>
              <ValidationProvider
                ref="pass"
                :rules="{ min: 8, required: true, regex: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$/ }"
                v-slot="{ errors }"
                name="mot de passe"
              >
                <InputPassword v-model="password"/>
                <span class="error">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            <!-- Submit -->
            <div class="form-validate">
              <button class="btn btn-submit" :disabled="!valid" :class="{disabled: !valid}">Valider</button>
            </div>
          </form>
        </ValidationObserver>

        <div class="requirements">
          <p>Doit contenir au moins</p>
          <ul>
            <li>8 caractères</li>
            <li>1 chiffre</li>
            <li>1 majuscule</li>
          </ul>
        </div>
      </div>
    </section>

    <FooterSmall class="end" />
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import InputPassword from '@/components/InputPassword'

export default {
  data () {
    return {
      password: ''
    }
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    InputPassword
  },
  created () {
    // Can not see this page if no data required filled
    const requiredData = [
      'cardNum',
      'firstName',
      'phone',
      'lastName',
      'civility',
      'isOptinSms',
      'isOptinEmail'
    ]

    const hasKeyList = this.$store.state.subscribeData ? Object.keys(this.$store.state.subscribeData) : []
    const isEmpty = value => value === undefined || value === '' || value === null
    const reducer = (value, key) => value || hasKeyList.indexOf(key) < 0 || isEmpty(this.$store.state.subscribeData[key])
    if (!this.$store.state.subscribeData ||
      requiredData.reduce(reducer, false)) {
      this.$router.push({ name: 'register2' })
    }
  },
  methods: {
    submit () {
      this.isLoading = true

      this.$store.dispatch('register3', {
        ...this.$store.state.subscribeData,
        password: this.password
      })
        .then(() => {
          // Route can be auto-changed if clubist logged
          if (this.$route.name !== 'dashboard') {
            this.$router.push({ name: 'dashboard' })
          }

          this.$eventHub.$emit('refresh-clubist')
        })
        .catch(error => {
          if (this.$refs.pass) {
            this.$refs.pass.applyResult({
              errors: [this.$t(error.message)],
              valid: false,
              failedRules: {}
            })
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  display: flex;
  flex-direction: column;
}
.mot-de-passe {
  .section-header {
    justify-self: center;
    align-self: center;
  }
  h2 {
    text-align: center;
  }
}
.requirements {
  text-align: left;
  align-self: flex-start;
  margin: 25px 0;
  p {
    font-weight: bold;
    margin-bottom: 15px;
  }
  ul {
    li:before {
      content: '✓';
      margin-right: 5px;
    }
  }
}
.footer-small.end {
  margin-top: auto;
}
</style>
