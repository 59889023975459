<template>
  <div class="inscription page">
    <section class="section-header">
      <router-link class="back" :to="{ name: 'register1' }">
        <img src="../assets/svg/back.svg" alt="retour" width="14" height="23" />
      </router-link>
      <div class="container">
        <h2 v-if="accountExists">Un compte est déjà associé à ce numéro de carte</h2>
        <h2 v-else>Veuillez renseigner<br>vos informations personnelles</h2>
        <p v-if="accountExists" class="tagline">Veuillez vérifier vos informations personnelles</p>

        <ValidationObserver v-slot="{ valid }" ref="validationObserver" class="form" @submit.prevent>
          <form>

            <!-- Civility -->
            <ValidationProvider rules="required" v-slot="{ errors }" name="civilité">
              <div class="civility form-group radio-group">
                <label for class="label">Civilité*</label>
                <label class="label label-radio">
                  Mme
                  <input
                    type="radio"
                    checked="checked"
                    class="input-radio"
                    value="female"
                    v-model="inputCivility"
                  />
                  <span class="checkmark"></span>
                </label>
                <label class="label label-radio">
                  M.
                  <input type="radio" value="male" class="input-radio" v-model="inputCivility"/>
                  <span class="checkmark"></span>
                </label>
              </div>
              <span class="error">{{ errors[0] }}</span>
            </ValidationProvider>

            <!-- Name -->
            <div class="form-row">
              <div class="form-group">
                <label for class="label">Nom*</label>
                <ValidationProvider :rules="{ required: true, regex: /^(?:(?![×÷])[ \-'aA-zZÀ-ÿ])+$/ }" v-slot="{ errors }" name="nom">
                  <input type="text" class="input-text" v-model="inputLastName" />
                  <span class="error">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>

              <div class="form-group">
                <label for class="label">Prénom*</label>
                <ValidationProvider :rules="{ required: true, regex: /^(?:(?![×÷])[ \-'aA-zZÀ-ÿ])+$/ }" v-slot="{ errors }" name="prénom">
                  <input type="text" class="input-text" v-model="inputFirstName" />
                  <span class="error">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>

            <!-- Phone number -->
            <div class="form-row">
              <div class="form-group">
                <label for class="label">N° de téléphone*</label>
                <ValidationProvider rules="required|numeric|digits:12|marocPhoneCode|marocPhoneStart" v-slot="{ errors }" name="téléphone">
                  <input type="text" class="input-text" maxlength="12" placeholder="212XXXXXXXXX" v-model="inputPhone" />
                  <span class="error">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>

              <div class="form-group">
                <label for class="label">Email{{ inputOptinEmail ? '*' : '' }}</label>
                <ValidationProvider :rules="inputOptinEmail ? 'email|required|noArabicChars' : 'email|noArabicChars'" v-slot="{ errors }" name="email">
                  <input type="text" class="input-text" v-model="inputEmail" />
                  <span class="error">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>

            <div class="form-row">
              <!-- Birth date -->
              <div class="form-group">
                <label for class="label">Date de naissance</label>
                <ValidationProvider rules="min:10|date|ageMin:18|ageMax:130" v-slot="{ errors }" name="date de naissance">
                  <TheMask
                    type="tel"
                    class="input-text date-fix"
                    v-model="inputBirthdate"
                    placeholder="jj/mm/aaaa"
                    mask="##/##/####"
                    :masked="true"/>
                  <span class="error">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>

              <!-- City -->
              <div class="form-group">
                <label for class="label">Ville</label>
                <ValidationProvider :rules="{ regex: /^(?:(?![×÷])[ \-'aA-zZÀ-ÿ])+$/ }" v-slot="{ errors }" name="Ville">
                  <input type="text" class="input-text" v-model="inputCity" />
                  <span class="error">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>

            <!-- Taxi -->
            <ValidationProvider rules="required" name="statut" v-slot="{ errors }">
              <p class="form-group left label">Je dispose d'un* :</p>

              <div class="form-group-status">
                <div class="form-group checkbox-group taxi">
                  <label class="label label-radio">
                    Véhicule Léger

                    <input
                      id="particulier"
                      class="input-radio"
                      type="radio"
                      value="particulier"
                      v-model="inputStatus"
                    />

                    <span class="checkmark"></span>
                  </label>
                </div>

                <div class="form-group checkbox-group taxi">
                  <label class="label label-radio">
                    Taxi

                    <input
                      id="taxi"
                      class="input-radio"
                      type="radio"
                      value="taxi"
                      v-model="inputStatus"
                    />

                    <span class="checkmark"></span>
                  </label>
                </div>

                <div class="form-group checkbox-group taxi">
                  <label class="label label-radio">
                    Poids Lourd

                    <input
                      id="poids-lourd"
                      class="input-radio"
                      type="radio"
                      value="poids-lourd"
                      v-model="inputStatus"
                    />

                    <span class="checkmark"></span>
                  </label>
                </div>

                <span class="error">{{ errors[0] }}</span>
              </div>

            </ValidationProvider>

            <!-- Opt-in -->
            <div class="optin">
              <p class="resume">
                J’accepte être contacté(e) par TotalEnergies Maroc
                à des fins d’enquêtes ou d’information et
                souhaite recevoir les offres commerciales* :
              </p>
              <ValidationProvider rules="required" v-slot="{ errors }" name="SMS">
                <div class="form-group radio-group">
                  <p class="label">Par SMS</p>
                  <label class="label label-radio">
                    Oui
                    <input type="radio" name="sms" :value="true" v-model="inputOptinSms" class="input-radio" />
                    <span class="checkmark"></span>
                  </label>
                  <label class="label label-radio">
                    Non
                    <input type="radio" name="sms" :value="false" v-model="inputOptinSms" class="input-radio" />
                    <span class="checkmark"></span>
                  </label>
                  <span class="error">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
              <ValidationProvider rules="required" v-slot="{ errors }" name="email|noArabicChars">
              <div class="form-group radio-group">
                <p class="label">Par email</p>
                <label class="label label-radio">
                  Oui
                  <input type="radio" name="email" :value="true" v-model="inputOptinEmail" class="input-radio" />
                  <span class="checkmark"></span>
                </label>
                <label class="label label-radio">
                  Non
                  <input type="radio" name="email" :value="false" v-model="inputOptinEmail" class="input-radio" />
                  <span class="checkmark"></span>
                </label>
                <span class="error">{{ errors[0] }}</span>
              </div>
              </ValidationProvider>
            </div>

            <!-- SMS language -->
            <div class="language">
              <ValidationProvider rules="required" v-slot="{ errors }" name="language">
                <div class="language-group">
                  <p class="label">Langue pour recevoir mes communications email/SMS*</p>
                  <label class="label label-radio">
                    Français
                    <input type="radio" name="language" value="fr" v-model="inputLanguage" class="input-radio" />
                    <span class="checkmark"></span>
                  </label>
                  <label class="label label-radio">
                    Arabe
                    <input type="radio" name="language" value="ar" v-model="inputLanguage" class="input-radio" />
                    <span class="checkmark"></span>
                  </label>
                  <span class="error">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </div>

            <!-- Terms and conditions -->
            <div>
              <ValidationProvider rules="required|checked" v-slot="{ errors }" name="legals">
                <div class="form-group checkbox-group left">
                  <label class="label label-checkbox empty">
                    <input
                      id="legals"
                      class="input-checkbox"
                      type="checkbox"
                      v-model="inputLegals"
                    />
                    <span class="checkmark"></span>
                  </label>

                  J’ai lu et j’accepte les <button @click="$eventHub.$emit('modal-conditions')" class="btn-underline-simple-white">Conditions Générales d’Utilisation</button> du TotalEnergies Club*
                  <span class="error">{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </div>

            <div v-if="apiError" class="form-error">
              <span class="error" v-html="apiError"></span>
            </div>

            <p class="notice">
              <sup>*</sup>Champs obligatoires
            </p>

            <!-- Submit -->
            <div class="form-validate">
              <button
                class="btn btn-submit"
                :disabled="!valid || (inputOptinEmail && !inputEmail)"
                :class="{disabled: !valid || (inputOptinEmail && !inputEmail)}"
                @click="submit()"
              >Valider</button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </section>

    <!-- Contact -->
    <section class="section-contact" v-if="accountExists">
      <div class="container">
        <h2>Ces données ne sont pas les vôtres?</h2>
        <a :href="'mailto:' + emailTotal" class="btn btn-inverse">Contacter le Service Client</a>
      </div>
    </section>

    <!-- Legals -->
    <MentionsLegales></MentionsLegales>

    <FooterSmall />
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapGetters } from 'vuex'
import { TheMask } from 'vue-the-mask'
import MentionsLegales from '@/components/MentionsLegales.vue'
import { config } from '@/config.js'
import { frDateToStdDate, stdDateToFrDate } from '@/pure/dates.js'

const getSubscribeData = (store, name) => {
  if (store.state.subscribeData) {
    return store.state.subscribeData[name]
  } else if (store.state.clubist) {
    return store.state.clubist[name]
  }
  return null
}

export default {
  data () {
    return {
      inputFirstName: getSubscribeData(this.$store, 'firstName') || '',
      inputLastName: getSubscribeData(this.$store, 'lastName') || '',
      inputCivility: getSubscribeData(this.$store, 'civility') || '',
      inputPhone: getSubscribeData(this.$store, 'phone') || '212',
      inputEmail: getSubscribeData(this.$store, 'email') || '',
      inputBirthdate: stdDateToFrDate(getSubscribeData(this.$store, 'birthDate')) || '',
      inputCity: getSubscribeData(this.$store, 'city') || '',
      inputOptinSms: getSubscribeData(this.$store, 'isOptinSms'),
      inputOptinEmail: getSubscribeData(this.$store, 'isOptinEmail'),
      inputIsTaxi: getSubscribeData(this.$store, 'isTaxi') || false,
      inputStatus: null,
      inputLanguage: getSubscribeData(this.$store, 'language'),
      apiError: null,
      emailTotal: config.contact.email,
      inputLegals: false
    }
  },
  computed: {
    ...mapGetters([
      'firstName',
      'lastName',
      'civility'
    ]),
    accountExists () {
      return this.$store.getters.firstName !== ''
    }
  },
  watch: {
    inputFirstName: 'removeApiError',
    inputLastName: 'removeApiError',
    inputCivility: 'removeApiError',
    inputPhone: 'removeApiError',
    inputEmail: 'removeApiError',
    inputBirthdate: 'removeApiError',
    inputCity: 'removeApiError',
    inputOptinSms: 'removeApiError',
    inputOptinEmail: 'removeApiError',
    inputIsTaxi: 'removeApiError',
    inputLanguage: 'removeApiError',
    inputStatus (val) {
      if (val === 'taxi') {
        this.inputIsTaxi = true
      } else {
        this.inputIsTaxi = false
      }
    }
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    MentionsLegales,
    TheMask
  },
  created () {
    // Can not see this page if no card and no name filled
    if (!this.$store.state.subscribeData ||
      !this.$store.state.subscribeData.cardNum ||
      !this.$store.state.subscribeData.lastName) {
      this.$router.push({ name: 'register1' })
    }
  },
  methods: {
    removeApiError () {
      this.apiError = null
    },
    submit () {
      this.isLoading = true
      this.$refs.validationObserver.validate()
        .then(isValid => {
          if (!isValid) {
            throw new Error('Invalid form')
          }
          return isValid
        })
        .then(() => this.$store.dispatch('register2', {
          cardNum: getSubscribeData(this.$store, 'cardNum'),
          firstName: this.inputFirstName,
          lastName: this.inputLastName,
          civility: this.inputCivility,
          phone: this.inputPhone,
          email: this.inputEmail,
          birthDate: frDateToStdDate(this.inputBirthdate),
          city: this.inputCity,
          language: this.inputLanguage,
          isOptinSms: this.inputOptinSms,
          isOptinEmail: this.inputOptinEmail,
          isTaxi: this.inputIsTaxi || false
        }))
        .then(() => {
          this.$router.push({ name: 'register3' })
        })
        .catch(error => {
          this.apiError = this.$t(error.message)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
h2 {
  margin-bottom: 2em;
}
.inscription {
}
.input-text::placeholder {
  color: rgba(#FFF, 0.5);
}
.section-contact {
  background-color: #fff;
  color: $blue;
  text-align: center;
  margin-top: 3em;
  .container {
    padding: 40px 30px 15px;
  }
}
.resume {
  text-align: left;
  margin-bottom: 1.2em;
}
.taxi {
  margin-bottom: 2.5em;
}
.radio-group {
  margin-bottom: 1em;
}
.notice {
  margin-bottom: 6em;
  text-align: left;
}
.language-group {
  text-align: left;
  .label {
    text-align: left;
    margin-bottom: 1em;
  }
  .label-radio {
    display: inline-block;
  }
}
.section-header {
  margin-bottom: 0;
  .container {
    padding-bottom: 0;
  }
}
@include media('>tablet') {
  .civility {
    justify-content: center;
  }
  .radio-group {
    width: 50%;
    display: inline-flex;
    &:last-child>* {
      margin: 0 0 0 50px;
    }
  }
  .section-contact {
    margin-left: auto;
    margin-right: auto;
  }
  .notice {

  }
  .optin {
    margin-bottom: 2em;
    .radio-group {
      .label:first-child {
        margin: 0;
      }

      &.right {
        .label:first-child {
          margin-left: 50px;
        }
        .label:last-child {
          margin: 0;
        }
      }
    }
  }
}
.form-group.left {
  text-align: left;
}
.form-group-status {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

@include media('>large') {
  .section-contact {
    position: absolute;
    top: 400px;
    right: calc((100vw - 1400px) / 4); // On the right but adaptative
  }
}
</style>
